import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last Day of Metcon Week`}</em></p>
    <p><strong parentName="p">{`Christina`}</strong></p>
    <p>{`20 minute AMRAP of:`}</p>
    <p>{`9 Pull-ups`}</p>
    <p>{`9 Squat Cleans (95/65 lbs)`}</p>
    <p>{`9 Kettlebell Swings (53/35)`}</p>
    <p>{`9 Toes-to-Bar`}</p>
    <p>{`9 Push Press (95/65 lbs)`}</p>
    <p>{`9 Burpees`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids this Saturday.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      